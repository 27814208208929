<template>
  <div :class="{'has-logo':showLogo}">
    <logo v-if="showLogo" :collapse="isCollapse" />
    <el-scrollbar wrap-class="scrollbar-wrapper">
      <el-menu
        :default-active="activeMenu"
        :collapse="isCollapse"
        :background-color="variables.menuBg"
        :text-color="variables.menuText"
        :unique-opened="true"
        :active-text-color="variables.menuActiveText"
        :collapse-transition="false"
        mode="vertical"
      >
        <sidebar-item
          v-for="(route,index) in routers"
          :key="index"
          :item="route"
          :base-path="route.path"
        />
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Logo from "./Logo";
import SidebarItem from "./SidebarItem";
import variables from "@/styles/variables.scss";
import { log } from "util";
import { getToken } from "@/utils/auth";
export default {
  components: { SidebarItem, Logo },
  props:["routers"],
  data() {
    return {
      user: [],//菜单栏
    };
  },
  computed: {
    // routers(){
    //   console.log(this.$router.options.routes,'router');
      
    //   return this.$router.options.routes
    // },
    ...mapGetters(["sidebar"]),
    routes() {
    },
    activeMenu() {
      const route = this.$route;
      const { meta, path } = route;
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      return path;
    },
    showLogo() {
      return this.$store.state.settings.sidebarLogo;
    },
    variables() {
      return variables;
    },
    isCollapse() {
      return !this.sidebar.opened;
    }
  },
};
</script>
<style>
.el-submenu .el-menu-item {
  text-align: center;
}
.el-submenu__title {
  color: #FFF !important;
  background-color: #283643 !important;
}
.el-menu-item.is-active{
  background-color: #283643 !important;
}
#app .sidebar-container .el-scrollbar{
  background-color: #283643;
}
.sidebar-container .el-submenu .el-menu-item{
 background-color: #283643 !important;
}
.sidebar-container .el-submenu .el-menu-item{
  background-color: #283643 !important;
}
</style>
