import axios from "axios";
import store from "@/store";
import { getToken } from "@/utils/auth";
import { Message, MessageBox} from 'element-ui'
let service = axios.create({
  baseURL: "/api",
  timeout: 5000 // 设置超时
});
//设置请求头
axios.interceptors.request.use(
  config => {
    config.headers["userId"] = getToken().userId;
    config.headers["token"] = getToken().Token;
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);
// axios返回拦截
axios.interceptors.response.use(
  function(response) {
    // token 已过期，重定向到登录页面
    let isShow=true;//解决数据中心多个接口同时返回20000时，多次调用此方法
    if (response.data.state === -20000) {
      if(isShow){
        MessageBox.confirm('请重新登录，3秒后自动跳转!', '提示', {
          showConfirmButton:false,
          showCancelButton:false,
          showClose:false,
          closeOnClickModal:false,
          type: 'warning',
          center: true,
        }).then(() => {    
          console.log('确定')     
        });
        isShow=false;
        setTimeout(()=>{
          window.location.href="/#/login";
          isShow=true;
         },3000);
      }
    }
    return response;
  },
  function(error) {
    return Promise.reject(error);
  }
);

export default service;
