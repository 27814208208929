import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

/* Layout */
import Layout from '@/layout'
export const constantRoutes = [
  {
    path: '/',
    redirect: '/login',
  },
  {
    path: '/login',
    name:'login',
    component: () => import('@/views/login/index'),
    hidden: true
  },

  {
    path: '/',
    component: Layout,
    redirect: '/DataCenter',
    children: [{
      path: 'DataShow',
      component: () => import('@/views/DataCenter/index'),
      meta: { title: '数据中心', icon: 'dashboard', requireAuth: true }//左侧导航栏名字和图标
    }]
  },
  // 运营管理
  {
    path: '/operationManagement',
    component: Layout,
    alwaysShow: true,
    permission: "yunyingController",
    redirect: '/Engineerservices',
    meta: { title: '运营管理', icon: 'example', requireAuth: true },
    children: [
      {
        path: 'lossSupplement',
        permission: "liushuibudanController",
        alwaysShow:true,//只有一个子组件显示父级title
        component: () => import('@/views/operationManagement/lossSupplement/index'),
        meta: { title: '流水补单'},
        children:[
          {
            path:'index',
            permission: "liushuibudanController",
            meta:{ title: '流水补单', requireAuth: true },
            component: () => import('@/views/operationManagement/lossSupplement/losssupplement')
          }
        ]
      },
      {
        path: 'ReconciliationMan',
        permission: "projectContractor:manager",
        alwaysShow:true,//只有一个子组件显示父级title
        component: () => import('@/views/operationManagement/ReconciliationMan/index'),
        meta: { title: '对账管理'},
        children:[
          {
            path:'yzduizahngController',
            permission: "yzduizahngController",
            meta:{ title: '云纵对账', requireAuth: true },
            component: () => import('@/views/operationManagement/ReconciliationMan/yzReconciliation')
          }
        ]
      },
      {
        path: 'ProfitSharingAudit',
        permission: "fenrunshenheController",
        alwaysShow:true,//只有一个子组件显示父级title
        component: () => import('@/views/operationManagement/ProfitSharingAudit/index'),
        meta: { title: '分润审核'},
        children:[
          {
            path:'profitExamine',
            permission: "fenrunshenheController",
            meta:{ title: '分润审核', requireAuth: true },
            component: () => import('@/views/operationManagement/ProfitSharingAudit/profitExamine')
          }
        ]
      }
    ]
  },
  // 工程服务商
  {
    path: '/serviceProvider',
    component: Layout,
    alwaysShow: true,
    permission: "projectContractorController",
    redirect: '/Engineerservices',
    meta: { title: '服务商', icon: 'nested', requireAuth: true },
    children: [
      {
        path: 'EngineerInformation',
        permission: "projectContractor:manager",
        component: () => import('@/views/EngineerInformation/index.vue'),
        meta: { title: '服务商信息'}
      },
      {
        path: 'serviceprovider',
        permission: "projectContractor:manager",
        component: () => import('@/views/serviceProvider/serviceprovider.vue'),
        meta: { title: '服务商管理'}
      },
    ]
  },
  
// 商户管理
  {
    path: '/Management',
    component: Layout,
    alwaysShow: true,
    redirect: '/Management',
    permission: "shopController",
    meta: { title: '商户管理', icon: 'nested', requireAuth: true },
    children: [
      {
        path: 'Merchant',
        permission: "shop:manager",
        component: () => import('@/views/Merchant/index'),
        meta: { title: '商户信息' }
      },
      {
        path: 'Management',
        permission: "shop:manager",
        component: () => import('@/views/Management/index'),
        meta: { title: '商户管理' }
      }
    ]
  },
// 订单
  {
    path: '/order',
    component: Layout,
    alwaysShow: true,
    redirect: '/order',
    permission: "orderTradeController",
    meta: { title: '订单流水', icon: 'order', requireAuth: true },
    children: [
      // {
      //   path: 'Information',
      //   permission: "orderTrade:orderInfo",
      //   component: () => import('@/views/Order/Information.vue'),
      //   meta: { title: '订单信息'}
      // },
      {
        path: 'payFlow',
        permission: "orderTrade:tradeInfo",
        component: () => import('@/views/Order/payFlow'),
        meta: { title: '支付流水'}
      }
    ]
  },
  // 服务商分润
  {
    path: '/engineerDistributeProfit',
    component: Layout,
    alwaysShow: true,
    redirect: '/order',
    permission: "projectProfitController",
    meta: { title: '服务商分润', icon: 'money', requireAuth: true },
    children: [
      {
        path: 'distributeProfitReportForm',
        permission: "projectProfit:queryDayProfit",
        component: () => import('@/views/engineerDistributeProfit/distributeProfitReportForm'),
        meta: { title: '分润报表'}
      },
      {
        path: 'engineerFlowWater',
        permission: "projectProfit:queryTradeProfit",
        component: () => import('@/views/engineerDistributeProfit/engineerFlowWater'),
        meta: { title: '流水分润'}
      }
    ]
  },
// 收款渠道
  {
    path: '/Receivables',
    component: Layout,
    alwaysShow: true,
    redirect: '/Receivables',
    permission: "mchController",
    meta: {
      title: '收款渠道',
      icon: 'nested', 
      requireAuth: true
    },
    children: [
      {
        path: 'Wechat',
        permission: "mch:wx",
        settlementChannel:2,
        component: () => import('@/views/Receivables/Wechat'), // Parent router-view
        meta: { title: '微信直连' },
      },
      {
        path: 'Alipay',
        permission: "mch:ali",
        settlementChannel:1,
        component: () => import('@/views/Receivables/Alipay'),
        meta: { title: '支付宝直连' }
      },
      {
        path: 'CloudVertical',
        permission: "mch:yz",
        settlementChannel:3,
        component: () => import('@/views/Receivables/CloudVertical'),
        meta: { title: '云纵收款' }
      },
      {
        path: 'kunSanBank',
        permission: "mch:ksb",
        settlementChannel:4,
        component: () => import('@/views/Receivables/kunSanBank'),
        meta: { title: '昆山农商行' }
      }
    ]
  },
  // 报表统计
  {
    path: '/ReportForm',
    component: Layout,
    alwaysShow: true,
    redirect: '/ReportForm',
    permission: "reportFormController",
    meta: { title: '报表统计', icon: 'order', requireAuth: true },
    children: [
      {
        path: 'daily',
        permission: "reportForm:shopChargeByDayLimits",
        component: () => import('@/views/ReportForm/daily'),
        meta: { title: '商户日报',  }
      },
      {
        path: 'MonthlyReport',
        permission: "reportForm:shopChargeByMonthLimits",
        component: () => import('@/views/ReportForm/MonthlyReport'),
        meta: { title: '商户月报', }
      },
      {
        path: 'projectDaily',
        permission: "reportForm:projectChargeByDayLimits",
        component: () => import('@/views/ReportForm/projectDaily'),
        meta: { title: '服务商日报',  }
      },
      {
        path: 'projectMonthlyDaily',
        permission: "reportForm:projectChargeByMonthLimits",
        component: () => import('@/views/ReportForm/projectMonthlyDaily'),
        meta: { title: '服务商月报', }
      },
      {
        path: 'SystemDaily',
        permission: "reportForm:sysChargeByDayLimits",
        component: () => import('@/views/ReportForm/SystemDaily'),
        meta: { title: '系统日报',  }
      },
      {
        path: 'SystemMonthlyDaily',
        permission: "reportForm:sysChargeByMonthLimits",
        component: () => import('@/views/ReportForm/SystemMonthlyDaily'),
        meta: { title: '系统月报', }
      }
    ]
  },
// 人力资源
  {
    path: '/HumanResources',
    component: Layout,
    alwaysShow: true,
    redirect: 'HumanResources',
    permission: "hrController",
    meta: {
      title: '人力资源',
      icon: 'nested', 
      requireAuth: true
    },
    children: [
      {
        path: 'Role',
        permission: "roleController",
        component: () => import('@/views/HumanResources/Role'),
        meta: { title: '角色管理' }
      },
      {
        path: 'Employee',
        permission: "memberController",
        component: () => import('@/views/HumanResources/Employee'), // Parent router-view
        meta: { title: '员工管理' },
      }
      
    ]
  },
  // 系统设置
  {
    path: '/System',
    component: Layout,
    alwaysShow: true,
    redirect: 'System',
    permission: "systemController",
    meta: {
      title: '系统管理',
      icon: 'tree',
      requireAuth: true
    },
    children: [
      {
        path: 'Log',
        permission: "system:queryLog",
        component: () => import('@/views/System/Log'), // Parent router-view
        meta: { title: '操作日志' },
      }
    ]
  },
  // 404 page must be placed at the end !!!
  // { path: '*', redirect: '/404', hidden: true }
]

const createRouter = () => new Router({
  mode: 'hash', // require service support
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})

const router = createRouter()
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router
