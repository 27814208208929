import Vue from "vue";

import "normalize.css/normalize.css"; 

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import locale from "element-ui/lib/locale/lang/zh-CN"; 
import echarts from "echarts";
Vue.prototype.$echarts = echarts;
import "@/styles/index.scss"; 

import App from "./App";
import store from "./store";
import router from "./router";
import '@/icons' 
import "@/permission"; 
import  "./utils/request.js";
import axios from "axios";
Vue.prototype.$axios = axios;
axios.defaults.baseURL = 'http://istpay-beta.sciseetech.com/pay'//每次请求携带api

import md5 from 'js-md5';
Vue.prototype.$md5 = md5;

// 轮播
import scroll from 'vue-seamless-scroll';
Vue.use(scroll)
Vue.use(scroll,{componentName: 'scroll-seamless'})
Vue.use(ElementUI, { locale });


Vue.config.productionTip = false;

new Vue({
  el: "#app",
  router,
  store,
  render: h => h(App)
});
