<template>
  <div :class="classObj" class="app-wrapper">
    <div v-if="device==='mobile'&&sidebar.opened" class="drawer-bg" @click="handleClickOutside" />
    <el-header style="height:60px;">
      <div>
        <img src="../assets/img/logo_03.png" alt />
      </div>
      <div class="admin">
        <p class="name"><span>{{hierarchy}}</span> {{user.nickname}}</p>
        <div style="padding-top:12px;margin-left:10px;">
          <img :src="HeadPortraitUrl?HeadPortraitUrl:infoStyle" alt style="border-radius:10px"/>
        </div>
        <div v-popover:popover class="name">安全中心&nbsp;&nbsp;&nbsp;|</div>
        <el-popover ref="popover" placement="left" width="400" v-model="visible">
          <div class="Security">
            <div>
              <div>姓名：{{user.nickname}}</div>
              <div @click="setPwd">重置密码</div>
            </div>
            <p>注册时间：{{user.createTime}}</p>
            <p>上次登录：{{user.lastLoginTime}}</p>
            <div>
              <div>
                授权微信扫码登录：
                <span>{{user.wxLoginAuth?"已经授权":"未授权"}}</span>
              </div>
              <div @click="CancelAuthorization(user.wxLoginAuth)">{{user.wxLoginAuth?"取消授权":"授权"}}</div>
            </div>
            <div id="qrcodes" ref="qrcode" style="width:120px;height:120px;margin:20px auto 0" v-show="display"></div>
          </div>
          <div style="text-align: right; margin: 20px 0 0">
            <el-button size="mini" type="text" @click="cancel" >取消</el-button>
            <el-button type="primary" size="mini" @click="sureData" style="color:#409EFF;">确定</el-button>
          </div>
        </el-popover>
        <div @click="logout" class="name">退出</div>
      </div>
    </el-header>
    <sidebar
      :routers="routers"
      class="sidebar-container"
      style="position:absolute;top:60px;heigth:91%;"
      ref="Series"
    />
    <div class="main-container">
      <div :class="{'fixed-header':fixedHeader}">
        <navbar />
      </div>
      <app-main />
    </div>
          <!-- 重置密码弹窗开始 -->
      <el-dialog title="重置密码" top="30vh" :visible.sync="dialogVisibleSet" width="15%">
        <div style="margin-bottom:20px;">
          <el-input placeholder="请输入密码" v-model="pwd" clearable></el-input>
        </div>
        <el-input placeholder="请再次确认密码" v-model="pwdTwo" clearable></el-input>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisibleSet = false">取 消</el-button>
          <el-button type="primary" @click="SureSetPwd">确 定</el-button>
        </span>
      </el-dialog>
      <!-- 重置密码弹窗结束 -->
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
import { Navbar, Sidebar, AppMain } from "./components";
import ResizeMixin from "./mixin/ResizeHandler";
import axios from "axios";
import { log } from "util";
import { removeToken } from "@/utils/auth";
export default {
  name: "Layout",
  components: {
    Navbar, //面包屑
    Sidebar, //左边导航栏
    AppMain //中间内容部分
  },
  mixins: [ResizeMixin],
  data() {
    return {
      user: { level: "" },
      routers: [],
      HeadPortraitUrl: "", //头像路径
      infoStyle:require('../assets/img/admin_02.png'),
      visible: false,
      hierarchy:'',
      dialogVisibleSet: false, //重置密码
      pwd: "", //密码
      pwdTwo: "", //确认密码
      display:false,//二维码div显示控制
    };
  },
  computed: {
    sidebar() {
      return this.$store.state.app.sidebar;
    },
    device() {
      return this.$store.state.app.device;
    },
    fixedHeader() {
      return this.$store.state.settings.fixedHeader;
    },
    classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === "mobile"
      };
    }
  },
  created() {
    this.getPermissions()
  },
  methods: {
     //拉取权限
    async getPermissions() {
      const userid = JSON.parse(sessionStorage.getItem("userId")); //获取用户id
       const users = JSON.parse(sessionStorage.getItem("user")); //获取用户id
      let shop = await this.$axios.post("shop/queryByUser"); 
      this.$axios
        .get("/user/getAuthorizationInfo?userId=" + userid)
        .then(res => {
          if (res.data.state === 1) {
            this.hierarchy =res.data.user.level===1?'系统平台：':res.data.user.level===2?'服务商平台：':'商户平台：';
            const users = { ...res.data.user };
            this.HeadPortraitUrl = users.wxHeadImgUrl;
            sessionStorage.setItem(
              "user",
              JSON.stringify({
                projectContractorId: users.projectContractorId,
                level: users.level,
                shopId: users.shopId
              })
            );

            // 侧边栏权限开始

            const authTbPage = res.data.authTbPage;
            // 找到第一层主模块
            let level = authTbPage.filter(item => item.level === 1);
            // 所有路由
            const $router = this.$router.options.routes;
            let obj = JSON.parse(JSON.stringify($router)); //深拷贝
            let obj1 = obj.filter(item => item.meta == undefined); //登录和数据中心模块
            let obj2 = obj.filter(item => item.meta !== undefined); //其他模块
            let obj3 = []; //最后组成的菜单栏

            // 根本后台返回的主模块取出第一层
            for (let i = 0; i < level.length; i++) {
              for (let j = 0; j < obj2.length; j++) {
                if (level[i].permission == obj2[j].permission) {
                  obj3.push(obj2[j]);
                }
              }
            }
            // 找到第二层
            let level2 = authTbPage.filter(item => item.level === 2);
            obj3.map(item => {
              item.children.forEach(v => {
                level2.forEach(i => {
                  if (v.permission.includes(i.permission)) {
                    item.children.push(v);
                  }
                });
              });
              return item;
            });
            obj3.forEach(item => {
              // set()括号里为保留重复的值，再用集合去重，在转数组，就得到了，后台返回的二级菜单
              item.children = Array.from(
                new Set(
                  item.children.filter(
                    i =>
                      item.children.indexOf(i) !== item.children.lastIndexOf(i)
                  )
                )
              );
            });
            // 根据返回的主模块组成对应的主模块功能
            obj1.reverse().forEach(item => {
              obj3.unshift(item);
            });
            const id = res.data.user.level;
            // 如果是系统登录
            if (id === 3) {
              obj3 = obj3.map(item => {
                if (item.permission === "shopController") {
                  item.children = item.children.filter(
                    v => v.path === "Merchant"
                  );
                }
                return item;
              });
            }
            // 如果level是工程商
            if (id === 2) {
              obj3 = obj3.map(item => {
                if (item.permission === "projectContractorController") {
                  item.children = item.children.filter(
                    v => v.path === "EngineerInformation"
                  );
                }
                if (item.permission === "shopController") {
                  item.children = item.children.filter(
                    v => v.path === "Management"
                  );
                }
                return item;
              });
            }
            // 如果是系统
            if (id === 1) {
              obj3 = obj3.map(item => {
                if (item.permission === "projectContractorController") {
                  item.children = item.children.filter(
                    v => v.path === "serviceprovider"
                  );
                }
                if (item.permission === "shopController") {
                  item.children = item.children.filter(
                    v => v.path === "Management"
                  );
                }
                return item;
              });
            }
            if(users.level==3){
              obj3.map(item=>{
              if(item.permission==="mchController"){
               item.children=item.children.filter(v=>v.settlementChannel===shop.data.shop.settlementChannel)}
              return item
            })
            }
            this.routers =[...obj3];
            // 侧边栏权限结束
            this.$store.state.user.users = { ...users };
            this.$store.state.user.level = users.level; //
            users.createTime = this.getTiem(users.createTime);
            users.lastLoginTime = this.getTiem(users.lastLoginTime);
            this.user = users;
          } else {
            this.$message.error('请退出重新登录');
          }
        });
    },
    // 时间转换
    getTiem(params) {
      let unixTimestamp = new Date(params * 1000); //时间戳转换成日期
      // 定义转换返回方式
      Date.prototype.toLocaleString = function() {
        return (
          this.getFullYear() +
          "年" +
          (this.getMonth() + 1) +
          "月" +
          +this.getDate() +
          "日" +
          " " +
          this.getHours() +
          "时" +
          this.getMinutes() +
          "分"
        );
      };
      params = unixTimestamp.toLocaleString();
      return params;
    },
    // 授权
    CancelAuthorization(data) {
      if (data === true) {
        const userid = JSON.parse(sessionStorage.getItem("userId"));
        this.$axios.post("user/authCancel?userId=" + userid).then(res => {
          if (res.data.state === 1) {
            this.DeleteSuccess();
            this.getPermissions();
          } else {
            this.DeleteFailure(res.data.msg);
          }
        });
      } else {
        this.display =true;
        document.querySelector("#qrcodes").innerHTML='';
        this.$axios.post("user/getUserAuthUrl").then(res => {
          const url = res.data.url;
          this.code = res.data.code;
          let qr = new QRCode("qrcodes", {
            width: 120,
            height: 120,
            text: url,
            render: "canvas",
            background: "#f0f",
            foreground: "#ff0"
          });
        });
      }
    },
    cancel(){
      this.visible=false;
      this.display =false;
      document.querySelector("#qrcodes").innerHTML='';
    },
    sureData(){
      this.visible=false;
      this.getPermissions();
      this.display =false;
      document.querySelector("#qrcodes").innerHTML='';
    },
    //修改密码
    setPwd() {
      this.dialogVisibleSet = true;
    },
    //确定修改密码
    SureSetPwd() {
      const {  password, id } = this.user;
      if (this.pwd === this.pwdTwo) {
        if(this.pwd!=="" & this.pwdTwo!==""){
          let pwds = this.$md5(this.pwd.trim()).toUpperCase();
          this.$axios
          .get("/member/reSetPw", {
            params: {
              userId:id,
              passWord: pwds
            }
          })
          .then(res => {
            if (res.data.state === 1) {
              this.DeleteSuccess();
              this.pwd='';
              this.pwdTwo='';
              this.dialogVisibleSet = false;
            } else {
              this.pwd='';
              this.pwdTwo='';
              this.DeleteFailure(res.data.msg);
            }
          });
        }else{
          this.PasswordErrors()
        }
      } else {
        this.PasswordError();
      }
    },
    // 密码错误提示
    PasswordError() {
      this.$notify.error({
        title: "错误",
        message: "两次输入的密码不匹配，请重新输入"
      });
    },
    // 密码未输入或只输入了一次
    PasswordErrors() {
      this.$notify.error({
        title: "错误",
        message: "你还未输出完整，请输出你需要重置的密码"
      });
    },
    //操作成功提示
    DeleteSuccess() {
      this.$message({
        message: "恭喜你，操作成功！",
        type: "success"
      });
    },
    //操作失败提示
    DeleteFailure(data) {
      this.$message.error(data);
    },
    handleClickOutside() {
      this.$store.dispatch("app/closeSideBar", { withoutAnimation: false });
    },
    //退出
    async logout() {
      this.$axios.post("user/doLogout").then(res => {});
      window.sessionStorage.removeItem("token");
      window.sessionStorage.removeItem("userId");
      removeToken();
      this.$router.push("/login");
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/mixin.scss";
@import "~@/styles/variables.scss";
.el-header {
  // background-color: #B3C0D1;
  width: 100%;
  text-align: center;
  background-color: #ECF5F9;
}
.el-header > div > img {
  position: absolute;
  left: 1%;
  margin-top: 4px;
}
.el-header > div > span {
  position: absolute;
  left: 14%;
  top: 2.5%;
  font-size: 20px;
  font-weight: 600;
}
.app-wrapper {
  @include clearfix;
  position: relative;
  min-height: 100%;
  width: 100%;
  &.mobile.openSidebar {
    position: fixed;
    top: 0;
  }
}
.drawer-bg {
  background: #000;
  opacity: 0.3;
  width: 100%;
  top: 0;
  height: 100%;
  position: absolute;
  z-index: 999;
}

.fixed-header {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9;
  width: calc(100% - #{$sideBarWidth});
  transition: width 0.28s;
}

.hideSidebar .fixed-header {
  width: calc(100% - 54px);
}
.mobile .fixed-header {
  width: 100%;
}
.sidebar-container {
  height: 93.8% !important;
}
.main-container {
  height: 93.8% !important;
  background-color: #EDF5F9;
}
.admin {
  display: flex;
  text-align: right;
  position: absolute;
  right: 2%;
  top: 0;
  font-size: 15px;
}
.admin > div {
  padding: 0 10px 0;
}
.admin > div > img {
  width:32px;
  height: 32px;
}
.admin > div:nth-child(3),
.admin > div:nth-child(5) {
  line-height: 50px;
}
.admin > div:nth-child(3):hover {
  color: rgb(67, 128, 197);
  cursor: pointer; 
}
.admin > div:nth-child(5):hover {
  color: rgb(67, 128, 197);
  cursor: pointer; 
}
.Security {
  font-weight: 600;
}
.Security > div {
  display: flex;
  justify-content: space-between;
  padding-right: 10px;
}
.Security > div > div > span {
  color: red;
}
.Security > div > div:nth-child(2):hover {
  color: rgb(6, 6, 151);
  cursor: pointer; 
}
// 登录账号名字体设置
.name{
  font-family:'KaiTi',Times,serif;
  font-weight: 600;
  font-size: 18px;
}
</style>
