import router from "./router";
import store from "./store";
import { Message } from "element-ui";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style
import { getToken } from "@/utils/auth"; // get token from cookie
import getPageTitle from "@/utils/get-page-title";
import Cookies from 'js-cookie'
NProgress.configure({ showSpinner: false }); // NProgress Configuration
router.beforeEach(async (to, from, next) => {
  //启动进度条
  NProgress.start()
  // 设置页面标题
  document.title = getPageTitle(); 
  //确定用户是否已经登录
  const token = sessionStorage.getItem("Token");
  // const hasToken = getToken();
  if (token == undefined && to.path == "/DataShow") {
    next({ path: "/login" });
  }
  //  if(!(from.path == '/') && to.path == '/DataShow'){
  //   next({ path: '/login' });
  // }
  else {
    if (from.path == "/" && to.path == "/login") {
      next();
    } else {
      next();
    }
  }
  if(from.path=="login" && to.path=="DataShow" && token==undefined){
    next({ path: "/login" });
  }

});
router.afterEach(() => {
  NProgress.done();
});
